import React from "react";

const Step3 = () => {
  return (
    <>
      <p className="card-content body-text">
        Theo dõi kết quả xổ số mỗi tuần và xổ số chung cuộc tại mục{" "}
        <span className="bold-text">"KẾT QUẢ"</span>
      </p>
    </>
  );
};

export default Step3;
