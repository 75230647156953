import React from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
// app assets
import appBingo from "../../assets/app_bingo.png";
import appJack from "../../assets/app_jack.png";
import appShake from "../../assets/app_shake.png";
// background
import bgBingo from "../../assets/background_bingo.png";
import bgShakeJack from "../../assets/background_shake-jack.png";
import buffer from "../../assets/bingo/buffer.png";
// prize table
import finalPrizeBingo from "../../assets/bingo/finalPrizeBingo.png";
import weeklyPrizeBingo from "../../assets/bingo/weeklyPrizeBingo.png";
import prizeShake from "../../assets/shake/prizeShake.png";
import prizeJack from "../../assets/jackpot/jackPrize.png";
import ecoin from "../../assets/bingo/guide/ecoin.svg";
// Header assets
import headerBuffer from "../../assets/bingo/header_buffer.svg";

// download icon
import appStore from "../../assets/download_appstore.svg";
import appStoreColor from "../../assets/download_appstore_color.svg";
import android from "../../assets/download_googleplay.svg";
import androidColor from "../../assets/download_googleplay_color.png";
import shakeBanner from "../../assets/Lac.png";
import bg from "../../assets/prizebg.png";
//
import QRCode from "../../assets/qrcode_demo.svg";
import jackBanner from "../../assets/Quay.png";
import step2Bingo from "../../assets/step2_bingo.png";
import step2Jack from "../../assets/step2_jack.png";
import step2Shake from "../../assets/step2_shake.png";
import step3Bingo from "../../assets/step3_bingo.png";
import step3Jack from "../../assets/step3_jack.png";
import step3Shake from "../../assets/step3_shake.png";
//
import bingoBanner from "../../assets/Xoso.png";
import { default as HeaderBingo } from "../../components/Bingo/Header";
import { default as BingoRuleContent } from "../../components/Bingo/RuleContent";
import { default as BingoStep1 } from "../../components/Bingo/Step1";
import { default as BingoStep2 } from "../../components/Bingo/Step2";
import { default as BingoStep3 } from "../../components/Bingo/Step3";
import Footer from "../../components/Footer";
import { default as HeaderJack } from "../../components/Jack/Header";
import { default as JackRuleContent } from "../../components/Jack/RuleContent";
import { default as JackStep1 } from "../../components/Jack/Step1";
import { default as JackStep2 } from "../../components/Jack/Step2";
import { default as JackStep3 } from "../../components/Jack/Step3";
import PrizeTable from "../../components/PrizeTable/PrizeTable";
import { default as Ecoin } from "../../components/Shake/ecoin";
import { default as BingoEcoin } from "../../components/Bingo/Ecoin";
import { default as HeaderShake } from "../../components/Shake/Header";
import { default as ShakeRuleContent } from "../../components/Shake/RuleContent";
import { default as ShakeStep1 } from "../../components/Shake/Step1";
import { default as ShakeStep2 } from "../../components/Shake/Step2";
import { default as ShakeStep3 } from "../../components/Shake/Step3";
import { getMobileOperatingSystem } from "../../helper";
import "./homePage.scss";

const Home = () => {
  const location = useLocation();

  const [headerRef, headerInView] = useInView({
    threshold: 0,
    rootMargin: "0px",
  });
  const [appRef, appInView] = useInView({
    threshold: 0,
    rootMargin: "-180px",
  });

  const [guideRef, guideInView] = useInView({
    threshold: 0,
    rootMargin: "-150px",
  });

  const [ruleRef, ruleInView] = useInView({
    threshold: 0,
    rootMargin: "-180px",
  });

  const getData = () => {
    let data = {};
    if (location.pathname === "/shake") {
      data.header = <HeaderShake inView={headerInView} />;
      data.appImg = appShake;
      data.bg = bgShakeJack;
      data.slogan = "Lắc càng siêu - ";
      data.slogan2 = "e-coins càng nhiều";
      data.qrContent =
        "Tải ứng dụng ECOE để tham gia “LẮC LÀ TRÚNG”, thu thập thêm E-coins, và săn giải thưởng xổ số hàng tỷ đồng.  ";
      data.appDesc =
        " Ứng dụng hiện đã có mặt trên App Store và Google Play cùng nhiều trò chơi hấp dẫn và phần quà giá trị đến từ ECOE.";

      data.step1Img = shakeBanner;
      data.secondImg = bingoBanner;
      data.thirdImg = jackBanner;
      data.step2Img = step2Shake;
      data.step3Img = step3Shake;
      data.step1Content = <ShakeStep1 />;
      data.step2Content = <ShakeStep2 />;
      data.step3Content = <ShakeStep3 />;
      data.ecoin = <Ecoin />;
      data.ecoinTitle = "Cách sử dụng E-coins";

      data.gameRuleContent = <ShakeRuleContent />;
      data.prizeTable = [{ label: "final", name: "E-Coin", prize: prizeShake }];
    }
    if (location.pathname === "/bingo") {
      data.header = <HeaderBingo inView={headerInView} />;
      data.appImg = appBingo;
      data.bg = bgBingo;
      data.slogan = "Chọn số hay – Trúng ngay tiền tỷ";
      data.qrContent =
        "Tải ứng dụng ECOE để tham gia Xổ số với giá trị giải thưởng lên đến hàng tỷ đồng và kết nối với cộng đồng mua bán bất động sản an toàn – minh bạch.      ";
      data.appDesc =
        "Ứng dụng hiện đã có mặt trên App Store và Google Play cùng nhiều trò chơi hấp dẫn và phần quà giá trị đến từ ECOE.";
      data.step1Img = bingoBanner;
      data.secondImg = shakeBanner;
      data.thirdImg = jackBanner;
      data.step2Img = step2Bingo;
      data.step3Img = step3Bingo;
      data.step1Content = <BingoStep1 />;
      data.step2Content = <BingoStep2 />;
      data.step3Content = <BingoStep3 />;
      data.ecoin = <BingoEcoin />;
      data.ecoinTitle = "Cách nhận E-coins";

      data.gameRuleContent = <BingoRuleContent />;
      // data.prizeTable = bingoPrizeTable;

      data.prizeTable = [
        { label: "final", name: "Giải chung cuộc", prize: finalPrizeBingo },
        { label: "weekly", name: "Giải tuần", prize: weeklyPrizeBingo },
      ];
    }
    if (location.pathname === "/jack") {
      data.header = <HeaderJack inView={headerInView} />;
      data.appImg = appJack;
      data.bg = bgShakeJack;
      data.slogan = "1 lượt quay – 100% trúng quà";
      data.qrContent =
        "Tải ứng dụng ECOE để tham gia QUAY LÀ TRÚNG với những quà tặng giá trị lên đến 10 triệu đồng mỗi tuần và kết nối với cộng đồng mua bán bất động sản an toàn – minh bạch.";
      data.appDesc =
        " Ứng dụng hiện đã có mặt trên App Store và Google Play cùng nhiều trò chơi hấp dẫn và phần quà giá trị đến từ ECOE.";

      data.step1Content = <JackStep1 />;
      data.step2Content = <JackStep2 />;
      data.step3Content = <JackStep3 />;
      data.step1Img = jackBanner;
      data.step2Img = step2Jack;
      data.step3Img = step3Jack;
      data.secondImg = shakeBanner;
      data.thirdImg = bingoBanner;
      data.ecoin = <Ecoin />;
      data.ecoinTitle = "Cách sử dụng E-coins";

      data.gameRuleContent = <JackRuleContent />;

      data.ecoin = <Ecoin />;
      data.prizeTable = [{ label: "final", name: "Voucher", prize: prizeJack }];
    }
    return data;
  };

  return (
    <div className="home-page">
      <div className="page-wrapper">
        <section className="header">
          <div className="header-content">
            <div className="header-left large-title-text">
              <p style={{ margin: 0 }}>
                {getData().slogan}
                <br />
                {getData()?.slogan2}
              </p>
              <div className="line" />
              <div className="header-sub-title body-text">
                <img src={QRCode} alt="qrcode" />
                <p>{getData().qrContent}</p>
              </div>
              <ScrollLink
                spy={true}
                smooth={true}
                offset={-143}
                to="guide"
                className="btn btn-outline header-btn button-text"
                style={{ cursor: "pointer" }}
              >
                Tìm hiểu thêm
              </ScrollLink>
            </div>
            <div className="header-right" ref={headerRef}>
              {/* <HeaderShake inView={headerInView} /> */}
              {getData().header}
              {/* <Header /> */}
            </div>
          </div>
          <img src={headerBuffer} className="header-buffer" alt="" />
        </section>
        {/* section 2 */}
        <div className="mb-download" id="mb-download">
          <a
            href={
              getMobileOperatingSystem() === "ios"
                ? "https://apps.apple.com/vn/app/ecoe/id1584988876"
                : "https://play.google.com/store/apps/details?id=vn.ecoe.apps.client"
            }
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={
                getMobileOperatingSystem() === "ios"
                  ? appStoreColor
                  : androidColor
              }
              alt=""
            />
          </a>
        </div>
        <section className="app-section" ref={appRef} id="app-section">
          <div className="container">
            <div className="app-content">
              <div className="app-content-left">
                <img
                  src={getData().appImg}
                  alt="visual"
                  className={appInView ? "app-inview" : ""}
                />
              </div>
              <div className={`app-content-right ${appInView ? "inview" : ""}`}>
                <p className="heading1-text mb-8 title">ECOE App</p>
                <p className="body-text mb-32">{getData().appDesc}</p>
                <a
                  href="https://apps.apple.com/vn/app/ecoe/id1584988876"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStoreColor} alt="appstore" className="mr-16" />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=vn.ecoe.apps.client"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidColor} alt="android" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* section-3 */}
      <section
        id="guide"
        className="guide-section"
        style={{
          backgroundImage: `url(${getData().bg})`,
        }}
      >
        <p
          className={`heading1-text title ${guideInView ? "title-inview" : ""}`}
        >
          Hướng dẫn chơi game
        </p>
        <div className="container">
          <div className="guide-content" ref={guideRef}>
            <div
              className={
                guideInView
                  ? "guide-content-top guide-inview"
                  : "guide-content-top "
              }
            >
              <div className="card">
                <p className="card-title heading2-text">Bước 1</p>
                {getData().step1Content}
                {/* <p className="card-content body-text">
                  Sau khi tải app ECOE, chọn mục{" "}
                  <span className="bold-text">“SỐ MAY MẮN” </span> để bắt đầu
                  tham gia vào trò chơi. (Nếu chưa tải click tại đây{" "}
                  <a className="link-text" href="/">
                    TẢI APP
                  </a>
                  )
                </p> */}
                <div className="card-image">
                  <img src={getData().step1Img} alt="huong-dan" />
                </div>
              </div>
              <div className="card">
                <p className="card-title heading2-text">Bước 2</p>
                {getData().step2Content}
                {/* <p className="card-content">
                  Tại màn hình chính, chọn{" "}
                  <span className="bold-text">“SỐ CỦA BẠN”.</span>
                  Tiếp theo chọn và nhập 6 chữ số tuỳ ý để tạo thành dãy số trên
                  vé của bạn.
                </p> */}
                <div className="card-image card-image-2">
                  {/* <img src={socuaban} alt="huong-dan" />
                  <img src={arrowDown} alt="huong-dan" />
                  <img src={chonso} alt="huong-dan" /> */}
                  <img src={getData().step2Img} alt="" />
                </div>
              </div>
              <div className="card">
                <p className="card-title heading2-text">Bước 3</p>
                {getData().step3Content}
                {/* <p className="card-content">
                  So sánh và xem kết quả sổ số mỗi ngày, mỗi tuần và chung cuộc
                  tại mục <span className="bold-text"> “KẾT QUẢ”.</span>
                </p> */}
                <div className="card-image card-image-2">
                  {/* <img src={result} alt="huong-dan" />
                  <img src={arrowDown} alt="huong-dan" />
                  <img src={result2} alt="huong-dan" /> */}
                  <img src={getData().step3Img} alt="" />
                </div>
              </div>
            </div>
            <div className="ruler"> </div>

            <div className="subtitle">
              <p className="heading2-text"> {getData().ecoinTitle} </p>
              <img src={ecoin} alt="ecoin" />
            </div>
            <div className="guide-content-bottom">
              <div className="card">
                {getData().ecoin}
                {/* <div className="content body-text">
                  E-Coins là đơn vị điểm thưởng dùng để đổi vé tham gia trò chơi
                  <span className="bold-text"> “SỐ MAY MẮN”. </span>Có 3 cách để
                  nhận thêm E-Coins:
                  <br /> – Chia sẻ link{" "}
                  <span className="bold-text"> “SỐ MAY MẮN” </span> trên mạng xã
                  hội.
                  <br /> – Giới thiệu bạn bè tải và tham gia game.
                  <br /> – Tham gia các trò chơi khác trong ECOE App.
                </div> */}
              </div>

              <div className="image-wrapper">
                <img src={getData().secondImg} alt="" />
              </div>

              <div className="image-wrapper">
                <img src={getData().thirdImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="rule-section" ref={ruleRef} id="rule">
        <img src={buffer} alt="" className="buffer" />
        <div className="container">
          <div className="rule-content body-text">
            <div
              className={`rule-content-left body-text ${
                ruleInView ? "rule-inview" : ""
              }`}
            >
              <div className="rule-title heading1-text">
                Thể lệ & giải thưởng{" "}
              </div>

              {getData().gameRuleContent}
              {/* <a className="link-text rule-download" href="/abc">
                <img src={pdfIcon} alt="" /> Thể lệ chi tiết (định dạng PDF)
              </a> */}
            </div>
            <div
              className={`rule-content-right ${
                ruleInView ? "rule-right-inview" : ""
              }`}
              style={{
                backgroundImage: `url(${bg}) `,
              }}
            >
              {/* <img
                src={getData().prizeTable}
                alt="phần thưởng"
                className="prize-table"
                onClick={handleChangePrizeTable()}
              /> */}
              <PrizeTable data={getData().prizeTable} />
            </div>
          </div>
        </div>
      </section>
      <section className="cta-section">
        <div className="container">
          <div className="cta-content">
            <p className="body-text">
              Tải ngay và tham gia các chương trình để có cơ hội trúng thưởng
              hàng tỷ đồng.
            </p>
            <a
              href="https://apps.apple.com/vn/app/ecoe/id1584988876"
              target="_blank"
              rel="noreferrer"
            >
              <img src={appStore} alt="" className="mr-16" />{" "}
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=vn.ecoe.apps.client"
              target="_blank"
              rel="noreferrer"
            >
              <img src={android} alt="" />{" "}
            </a>
            <a
              href={
                getMobileOperatingSystem() === "ios"
                  ? "https://apps.apple.com/vn/app/ecoe/id1584988876"
                  : "https://play.google.com/store/apps/details?id=vn.ecoe.apps.client"
              }
              target="_blank"
              className="cta-download"
              rel="noreferrer"
            >
              <img
                src={getMobileOperatingSystem() === "ios" ? appStore : android}
                alt=""
              />
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
