import React from "react";

const Step3 = () => {
  return (
    <>
      <p className="card-content body-text">
        Nếu bạn quay trúng 3 biểu tượng giống nhau sẽ được tặng E-coins. Bạn có
        thể dùng E-coins để đổi quà hoặc tham gia các trò chơi khác trên ứng
        dụng ECOE.
      </p>
    </>
  );
};

export default Step3;
