import React, { useState } from "react";

const activeTab = ["final", "weekly"];

const PrizeTable = ({ data }) => {
  const [active, setActive] = useState(activeTab[0]);
  const [prizeTable, setPrizeTable] = useState(data[0].prize);

  const handleChangeTab = (label) => {
    setActive(label);
    const prizeImg = data.filter((prize) => prize.label === label);
    setPrizeTable(prizeImg[0].prize);
  };
  return (
    <div className="prize-table">
      <div className="tabs">
        {data?.map((prize) => (
          <button
            key={prize.label}
            className={`tab ${prize.label === active ? "tab-active" : ""}`}
            onClick={() => handleChangeTab(prize.label)}
          >
            {prize.name}
          </button>
        ))}
      </div>
      <div className=" divider" />
      <img src={prizeTable} alt="prize" />
    </div>
  );
};

export default PrizeTable;
