import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import burger from "../../assets/burger.svg";
import close from "../../assets/close.svg";
import appStore from "../../assets/download_appstore.svg";
import android from "../../assets/download_googleplay.svg";
import Logo from "../../assets/Logo/Ecoe_logo_h.svg";
import WhiteLogo from "../../assets/Logo/Ecoe_logo_h_white.svg";
import { getMobileOperatingSystem } from "../../helper";
import useWindowSize from "../../hooks/useWindowSize";
import "./navbar.scss";

const Navbar = () => {
  const [navStatus, setNavStatus] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const windowSize = useWindowSize();

  // console.log(windowSize.width);

  const changeBackground = () => {
    if (window.scrollY > 40) {
      setNavStatus(true);
    } else {
      setNavStatus(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav
      className={`${navStatus ? "navbar active" : "navbar"} ${
        showMenu ? "nav-show" : ""
      }`}
    >
      <img
        // src={!navStatus ? WhiteLogo : Logo}
        src={showMenu ? WhiteLogo : !navStatus ? WhiteLogo : Logo}
        className={`${showMenu ? "logo-2" : "logo"}`}
        alt="logo"
      />
      <div onClick={handleShowMenu} className="nav-burger">
        {!showMenu ? (
          <img
            src={burger}
            alt=""
            className={`${navStatus ? "icon-active" : ""} `}
          />
        ) : (
          <img src={close} alt="" className={navStatus ? "icon-active" : ""} />
        )}
      </div>

      <ul className={`nav-burger-list ${showMenu ? "display" : ""} bold-text`}>
        <li>
          <Link
            activeClass="nav-item-active"
            to="header"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
          >
            Trang chủ
          </Link>
        </li>
        <li>
          <Link
            to="guide"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
          >
            Hướng dẫn
          </Link>
        </li>
        <li>
          <Link
            to="rule"
            spy={true}
            smooth={true}
            onClick={() => setShowMenu(false)}
          >
            Thể lệ
          </Link>
        </li>
        <div className="burger-download body-text">
          <p>
            Tải ứng dụng ECOE để tham gia các trò chơi với phần thưởng có giá
            trị hàng tỉ đồng. Đồng thời kết nối với cộng đồng mua bán bất động
            sản trên nền tảng của ECOE - an toàn và minh bạch.
          </p>
          {
            <a
              href={
                getMobileOperatingSystem() === "ios"
                  ? "https://apps.apple.com/vn/app/ecoe/id1584988876"
                  : "https://play.google.com/store/apps/details?id=vn.ecoe.apps.client"
              }
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={getMobileOperatingSystem() === "ios" ? appStore : android}
                alt=""
              />
            </a>
          }
        </div>
      </ul>

      <div className="nav-items bold-text">
        <Link
          activeClass="nav-item-active"
          className={!navStatus ? "nav-item" : "nav-item scrolled"}
          to="header"
          spy={true}
          smooth={true}
        >
          <span> Trang chủ</span>
        </Link>
        <Link
          activeClass="nav-item-active"
          className={!navStatus ? "nav-item" : "nav-item scrolled"}
          to="guide"
          spy={true}
          smooth={true}
          offset={-143}
        >
          <span> Hướng dẫn</span>
        </Link>
        <Link
          activeClass="nav-item-active"
          className={!navStatus ? "nav-item" : "nav-item scrolled"}
          to="rule"
          spy={true}
          smooth={true}
        >
          <span> Thể lệ</span>
        </Link>
        {/* <Link to="" className={`${styles.btnFilled} ${styles.btn}`}> */}
        <Link
          to={windowSize.width > 1025 ? "app-section" : "mb-download"}
          spy={true}
          smooth={true}
          className={!navStatus ? "btn btnFilledWhite" : "btn btnFilled"}
          style={{ cursor: "pointer" }}
        >
          Tải App
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
