import React from "react";
import pdfIcon from "../../assets/pdf-icon.svg";

const RuleContent = () => {
  return (
    <>
      <p>
        Tên chương trình: <span className="bold-text">QUAY LÀ TRÚNG</span>
      </p>
      <p>
        Thời gian diễn ra:
        <span className="bold-text">
          Từ ngày 01/11/2021 đến Tết Dương lịch 01/01/2022
        </span>
      </p>
      <br />
      <p>
        Khách hàng tải và đăng nhập ứng dụng thành công sẽ nhận được 3 lượt
        quay.
      </p>
      <br />
      <p>
        Mỗi ngày, khách hàng vào ứng dụng ECOE để được tặng thêm một lượt quay
      </p>
      <br />
      <p>
        Khách hàng có thể sở hữu thêm lượt{" "}
        <span className="bold-text"> "QUAY LÀ TRÚNG" </span> bằng cách đổi
        E-coins. 5 E-coins tương đương 1 lượt quay may mắn.
      </p>
      <br />
      <p>
        Tham gia
        <span className="bold-text"> "QUAY LÀ TRÚNG" </span> khách hàng có thể
        nhận giải thưởng hấp dẫn được trao mỗi tuần.
      </p>
      <br />
      <p>
        Đặc biệt, khi quay được 3 icon ECOE Vàng, khách hàng sẽ nhận ngay GIẢI
        ĐẶC BIỆT TUẦN của
        <span className="bold-text"> "QUAY LÀ TRÚNG" </span> lên đến 10 triệu
        đồng.
      </p>
      <a
        className="link-text rule-download"
        href="https://s3.ecoe.vn/ecoe-app/document/The-le-chi-tiet-Quay-la-trung_20211020.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <img src={pdfIcon} alt="" /> Thể lệ chi tiết (định dạng PDF)
      </a>
    </>
  );
};

export default RuleContent;
