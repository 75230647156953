import React from "react";
import back from "../../assets/bingo/header/Back.png";
// import backMb from "../../assets/bingo/header/Back-mb.png";
// import back from "../../assets/bingo/header/Back.png";
// import cardMb from "../../assets/bingo/header/Card-mb.png";
// import card from "../../assets/bingo/header/Card.png";
// import phoneMb from "../../assets/bingo/header/Phone-mb.png";
// import phone from "../../assets/bingo/header/Phone.png";'
import front from "../../assets/bingo/header/front.png";
import main from "../../assets/bingo/header/main.png";
import "./bingo.scss";

const Header = ({ inView }) => {
  return (
    <div className="bingo-header">
      {/* {size.width >= 767 ? (
        <>
          <img src={back} alt="" className={inView ? "back-inview" : "back"} />
          <img
            src={phone}
            alt=""
            className={`main ${inView ? "main-inview" : ""}`}
          />
          <img
            src={card}
            alt=""
            className={inView ? "front-inview" : "front"}
          />
        </>
      ) : (
        <>
          {" "}
          <img
            src={backMb}
            alt=""
            className={inView ? "back-inview" : "back"}
          />
          <img
            src={phoneMb}
            alt=""
            className={`main ${inView ? "main-inview" : ""}`}
          />
          <img
            src={cardMb}
            alt=""
            className={inView ? "front-inview" : "front"}
          />
        </>
      )} */}
      <div className="header-img-wrapper">
        <img src={back} alt="" className={inView ? "back-inview" : "back"} />
      </div>
      <div className="header-img-wrapper">
        <img
          src={main}
          alt=""
          className={`main ${inView ? "main-inview" : ""}`}
        />
      </div>
      <div className="header-img-wrapper">
        <img src={front} alt="" className={inView ? "front-inview" : "front"} />
      </div>
    </div>
  );
};

export default Header;
