import React from "react";
import pdfIcon from "../../assets/pdf-icon.svg";

const RuleContent = () => {
  return (
    <>
      <p>
        Tên chương trình: <span className="bold-text">SỐ MAY MẮN</span>
      </p>
      <p>
        Thời gian diễn ra:
        <span className="bold-text">
          {" "}
          Từ ngày 01/11/2021 đến Tết Dương lịch 01/01/2022
        </span>
      </p>
      <p>
        Khi khách hàng đăng nhập thành công ứng dụng ECOE, khách hàng sẽ được
        tặng một
        <span className="bold-text"> "SỐ MAY MẮN" </span>
      </p>
      <br />

      <p>
        Khách hàng có thể sở hữu thêm
        <span className="bold-text"> "SỐ MAY MẮN" </span> được quy đổi từ điểm
        E-Coins. 10 E-Coins đổi được thêm một{" "}
        <span className="bold-text"> "SỐ MAY MẮN" </span>
      </p>
      <br />
      <p>
        Các
        <span className="bold-text"> "SỐ MAY MẮN" </span> của khách hàng sẽ được
        ECOE tổng hợp và tổ chức livestream XỔ SỐ MỖI TUẦN với những giải thưởng
        hấp dẫn:
      </p>
      <br />
      <p>1 Giải Đặc biệt: trị giá 30 triệu đồng</p>
      <br />
      <p>1 Giải nhất: trị giá 15 triệu đồng</p>
      <br />
      <p>1 Giải nhì: trị giá 7,5 triệu đồng</p>
      <br />
      <p>
        Đặc biệt, khi sở hữu
        <span className="bold-text"> "SỐ MAY MẮN" </span> khách hàng có cơ hội
        tham gia livestream GALA XỔ SỐ CHUNG CUỘC với giải thưởng VÔ CÙNG GIÁ
        TRỊ vào ngày Tết Dương lịch 01/01/2022.
      </p>
      <br />
      <p>1 GIẢI ĐẶC BIỆT: Trị giá 2 tỷ đồng</p>
      <br />
      <p>1 GIẢI NHẤT: Trị giá 400 triệu đồng</p>
      <br />
      <p>1 GIẢI NHÌ: Trị giá 200 triệu đồng</p>
      <br />
      <p>1 GIẢI KHUYẾN KHÍCH: Trị giá 100 triệu đồng</p>
      <a
        className="link-text rule-download"
        href="https://s3.ecoe.vn/ecoe-app/document/The-le-chi-tiet-Xo-so-may-man_20211020.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <img src={pdfIcon} alt="" /> Thể lệ chi tiết (định dạng PDF)
      </a>
    </>
  );
};

export default RuleContent;
