import React from "react";

const Step2 = () => {
  return (
    <>
      <p className="card-content body-text">
        Tại giao diện game, chọn
        <span className="bold-text"> "SỐ CỦA BẠN"</span>. Sau đó nhập ngẫu nhiên
        6 con số để tạo thành dãy số may mắn của bạn.
      </p>
    </>
  );
};

export default Step2;
