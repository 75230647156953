import React from "react";

const Step3 = () => {
  return (
    <>
      <p className="card-content body-text">
        Sau mỗi lượt lắc, mỗi thành viên sẽ nhận số E-coins tương ứng mình lắc
        được.
      </p>
    </>
  );
};

export default Step3;
