import React from "react";

const Ecoin = () => {
  return (
    <div className="content body-text">
      E-coins là đơn vị điểm thưởng sử dụng trong các trò chơi và tính năng đặc
      biệt trên ứng dụng ECOE. Có 3 cách để thu thập thêm E-coins.
      <br /> - Chia sẻ game <span className="bold-text"> "Xổ số” </span> trên
      mạng xã hội
      <br /> - Giới thiệu bạn bè cùng tải và tham gia game
      <br /> - Tham gia các trò chơi khác trên ứng dụng mua và bán bất động sản
      ECOE
    </div>
  );
};

export default Ecoin;
