import React from "react";

const Step2 = () => {
  return (
    <>
      <p className="card-content body-text">
        Tại giao diện game, chọn <span className="bold-text"> "LẮC NGAY"</span>{" "}
        . sau đó chọn Phòng tham gia. Phòng càng nhiều thành viên, cơ hội lắc
        E-coins giá trị cao càng lớn.
      </p>
    </>
  );
};

export default Step2;
