import React from "react";
import "./footer.scss";
import logo from "../../assets/Logo/Ecoe_logo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-top">
            <div className="logo">
              <img src={logo} alt="log" />
            </div>
            <div className="info">
              <div className="bold-text name">
                Công ty cổ phần ECOE Việt Nam
              </div>
              <div className="address body-text small-text">
                134 Bạch Đằng, Phường 2, Quận Tân Bình, Thành phố Hồ Chí Minh.
                <span> 1900 27 27 27 </span>
                <span> cskh@ecoe.vn </span>
              </div>
            </div>
          </div>
          <div className="ruler" />
          <div className="footer-bottom small-text">
            Copyright © 2021 ECOE. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
