import React from "react";
import back from "../../assets/jackpot/header/Back.png";
import front from "../../assets/jackpot/header/Front.png";
import main from "../../assets/jackpot/header/Main.png";
import "./jack.scss";

const Header = ({ inView }) => {
  return (
    <div className="jack-header">
      {/* {size.width > 767 ? (
        <>
          <img src={back} alt="" className={inView ? "back-inview" : "back"} />
          <img src={main} alt="" className={inView ? "main-inview" : "main"} />
          <img
            src={front}
            alt=""
            className={inView ? "front-inview" : "front"}
          />
        </>
      ) : (
        <>
          {" "}
          <img
            src={backMb}
            alt=""
            className={inView ? "back-inview" : "back"}
          />
          <img
            src={mainMb}
            alt=""
            className={inView ? "main-inview" : "main"}
          />
          <img
            src={frontMb}
            alt=""
            className={inView ? "front-inview" : "front"}
          />
        </>
      )} */}
      <div className="header-img-wrapper">
        <img src={back} alt="" className={inView ? "back-inview" : "back"} />
      </div>
      <div className="header-img-wrapper">
        <img
          src={main}
          alt=""
          className={`main ${inView ? "main-inview" : ""}`}
        />
      </div>
      <div className="header-img-wrapper">
        <img src={front} alt="" className={inView ? "front-inview" : "front"} />
      </div>
    </div>
  );
};

export default Header;
