import React from "react";

const Ecoin = () => {
  return (
    <div className="content body-text">
      E-coins là đơn vị điểm thưởng sử dụng trong các trò chơi và tính năng đặc
      biệt trên ứng dụng ECOE. Bạn có thể sử dụng E-coins với nhiều tính năng
      khác nhau.
      <br /> - Đổi thêm vé trong game
      <span className="bold-text"> “SỐ MAY MẮN”. </span>
      <br /> - Tiếp tục tham gia game
      <span className="bold-text"> "QUAY LÀ TRÚNG” </span> trên mạng xã hội.
      <br /> - Tham gia các trò chơi khác hoặc nhận ưu đãi từ ứng dụng mua và
      bán bất động sản ECOE.
    </div>
  );
};

export default Ecoin;
