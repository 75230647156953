import React from "react";

const Step1 = () => {
  return (
    <>
      <p className="card-content body-text">
        Sau khi tải ứng dụng ECOE và đăng nhập thành công, chọn mục
        <span className="bold-text"> "SỐ MAY MẮN" </span> để bắt đầu tham gia
        vào trò chơi. (Nếu chưa tải ứng dụng click tại đây{" "}
        <a className="link-text" href="/">
          TẢI NGAY
        </a>
        )
      </p>
    </>
  );
};

export default Step1;
