import React from "react";
import pdfIcon from "../../assets/pdf-icon.svg";

const RuleContent = () => {
  return (
    <>
      <p>
        Tên chương trình: <span className="bold-text">LẮC LÀ TRÚNG</span>
      </p>
      <p>
        Thời gian diễn ra:
        <span className="bold-text">
          {" "}
          Từ ngày 01/11/2021 đến Tết Dương lịch 01/01/2022
        </span>
      </p>
      <p>
        Khách hàng tải và đăng nhập ứng dụng thành công sẽ nhận được 3 lượt lắc.
      </p>
      <br />

      <p>
        Khách hàng có thể xem video giới thiệu ứng dụng mua và bán bất động sản
        ECOE để nhận thêm tối đa 1 lượt lắc mỗi ngày cho 1 lượt xem.
      </p>
      <br />
      <p>
        Khách hàng chia sẻ ứng dụng trên mạng xã hội Facebook hoặc Zalo, mỗi
        lượt chia sẻ khách hàng sẽ nhận được 1 lượt lắc. Mỗi ngày, khách hàng
        tối đa có thể có 3 lượt lắc từ các chia sẻ.
      </p>
      <br />
      <p>
        Khách hàng chia sẻ bạn bè tải thành công và cùng chơi game, mỗi lượt
        chia sẻ khách hàng sẽ nhận được 1 lượt lắc. Mỗi ngày, khách hàng tối đa
        có thể có 3 lượt lắc từ các chia sẻ bạn bè.
      </p>
      <br />
      <p>
        {" "}
        Ngoài ra, khách hàng điểm danh mỗi ngày trên ứng dụng mua và bán bất
        động sản ECOE sẽ được tặng thêm 1 lượt lắc.
      </p>
      <a
        className="link-text rule-download"
        href="https://s3.ecoe.vn/ecoe-app/document/The-le-chi-tiet-Lac-la-trung_20211020.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <img src={pdfIcon} alt="" /> Thể lệ chi tiết (định dạng PDF)
      </a>
    </>
  );
};

export default RuleContent;
