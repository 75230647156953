import React from "react";

const Step2 = () => {
  return (
    <>
      <p className="card-content body-text">
        Tại giao diện game, chọn
        <span className="bold-text"> "Quay Ngay" </span>. Tiếp theo chọn và nhập
        6 chữ số tuỳ ý để tạo thành dãy số trên vé của bạn.
      </p>
    </>
  );
};

export default Step2;
