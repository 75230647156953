import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import slugs from "./constant/slugs";

import "./App.css";
import "./styles/styles.scss";
import "animate.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path={slugs.bingo} component={Home}></Route>
        <Route exact path={slugs.jack} component={Home}></Route>
        <Route exact path={slugs.shake} component={Home}></Route>
        <Route exact path="/">
          <Redirect to={slugs.bingo} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
